import React from 'react'

const BankID = ({ ...props }) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 45 46"
		>
			<path
				fill="#fff"
				d="M14.2 24.003l1.853-12.254h-2.022c-.927 0-2.149-.523-2.528-1.526-.127-.349-.38-1.526 1.18-2.66.547-.392.927-.828.969-1.177a.93.93 0 00-.253-.916c-.295-.305-.97-.523-1.812-.523-1.432 0-2.444.872-2.528 1.482-.084.48.295.829.59 1.047.885.698 1.096 1.7.548 2.66-.59.96-1.812 1.613-3.118 1.613H5.014c-.168 1.178-2.95 19.493-3.16 20.932h11.124c.042-.61.547-4.1 1.222-8.678z"
			></path>
			<path
				fill="#fff"
				d="M29.073 1.022H17.78l-1.517 9.899h1.938c1.053 0 2.065-.48 2.486-1.221.127-.218.21-.436.21-.61 0-.393-.252-.742-.547-.916-.758-.567-.885-1.178-.885-1.614v-.218c.169-1.046 1.517-2.18 3.329-2.18 1.095 0 1.896.262 2.401.741.422.436.632 1.047.506 1.657-.168.742-.885 1.352-1.306 1.657-1.096.785-.97 1.483-.885 1.701.21.61 1.096 1.003 1.77 1.003h2.95c4.002.044 6.15 1.919 5.435 6.367-.632 4.099-3.666 5.886-7.29 5.93l-1.517 9.463h2.107c8.975 0 16.264-5.974 17.739-15.35C46.517 5.688 39.354 1.022 29.073 1.022zM1.222 36.91h4.55c1.939 0 2.402 1.004 2.276 1.963-.127.741-.59 1.308-1.475 1.657 1.096.436 1.517 1.09 1.348 2.137-.21 1.308-1.306 2.267-2.738 2.267H0l1.222-8.023zm2.991 3.358c.885 0 1.307-.48 1.39-1.046.085-.61-.168-1.047-1.052-1.047H3.75l-.337 2.093h.8zm-.505 3.489c.927 0 1.432-.392 1.559-1.177.084-.655-.253-1.09-1.138-1.09h-.885l-.337 2.267h.8zM14.241 45.022c-1.18.087-1.77-.044-2.022-.567-.632.392-1.306.61-2.065.61-1.348 0-1.811-.698-1.685-1.526.042-.393.253-.742.632-1.047.716-.654 2.57-.741 3.286-1.264.043-.567-.168-.785-.842-.785-.8 0-1.433.261-2.57 1.046l.252-1.831c.97-.742 1.896-1.047 2.992-1.047 1.39 0 2.612.567 2.36 2.137l-.253 1.788c-.085.61-.085.828.59.828l-.675 1.658zm-2.022-2.748c-.632.393-1.812.35-1.938 1.178-.042.392.168.697.547.697.38 0 .843-.174 1.18-.436-.042-.13 0-.305.042-.567l.169-.872zM16.475 38.742h2.36l-.127.829c.758-.654 1.306-.916 2.065-.916 1.306 0 1.938.828 1.727 2.224l-.632 4.099h-2.36l.506-3.401c.084-.611-.084-.916-.548-.916-.379 0-.716.218-1.053.654l-.548 3.62h-2.36l.97-6.193zM24.354 36.91h2.36l-.59 3.969 2.275-2.137h2.907l-2.907 2.66 2.317 3.576h-2.991L25.913 42.1h-.042l-.422 2.878h-2.36l1.265-8.067z"
			></path>
			<path
				fill="#fff"
				d="M1.222 36.91h4.55c1.939 0 2.402 1.004 2.276 1.963-.127.741-.59 1.308-1.475 1.657 1.096.436 1.517 1.09 1.348 2.137-.21 1.308-1.306 2.267-2.738 2.267H0l1.222-8.023zm2.991 3.358c.885 0 1.307-.48 1.39-1.046.085-.61-.168-1.047-1.052-1.047H3.75l-.337 2.093h.8zm-.505 3.489c.927 0 1.432-.392 1.559-1.177.084-.655-.253-1.09-1.138-1.09h-.885l-.337 2.267h.8zM14.241 45.022c-1.18.087-1.77-.044-2.022-.567-.632.392-1.306.61-2.065.61-1.348 0-1.811-.698-1.685-1.526.042-.393.253-.742.632-1.047.716-.654 2.57-.741 3.286-1.264.043-.567-.168-.785-.842-.785-.8 0-1.433.261-2.57 1.046l.252-1.831c.97-.742 1.896-1.047 2.992-1.047 1.39 0 2.612.567 2.36 2.137l-.253 1.788c-.085.61-.085.828.59.828l-.675 1.658zm-2.022-2.748c-.632.393-1.812.35-1.938 1.178-.042.392.168.697.547.697.38 0 .843-.174 1.18-.436-.042-.13 0-.305.042-.567l.169-.872zM16.475 38.742h2.36l-.127.829c.758-.654 1.306-.916 2.065-.916 1.306 0 1.938.828 1.727 2.224l-.632 4.099h-2.36l.506-3.401c.084-.611-.084-.916-.548-.916-.379 0-.716.218-1.053.654l-.548 3.62h-2.36l.97-6.193zM24.354 36.91h2.36l-.59 3.969 2.275-2.137h2.907l-2.907 2.66 2.317 3.576h-2.991L25.913 42.1h-.042l-.422 2.878h-2.36l1.265-8.067z"
			></path>
			<path
				fill="#fff"
				d="M32.697 36.91h2.738l-1.221 8.024h-2.74l1.223-8.023zM36.742 36.91h3.876c2.992 0 3.877 2.268 3.581 4.143-.252 1.832-1.685 3.925-4.297 3.925h-4.34l1.18-8.067zm2.528 6.15c1.306 0 2.064-.655 2.275-2.094.169-1.047-.169-2.093-1.601-2.093h-.759l-.632 4.23.717-.044z"
			></path>
		</svg>
	)
}

export default BankID
