import React, { Fragment } from 'react'
import ReactPlayer from 'react-player'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import tw from 'twin.macro'

import AnimateText from '../components/AnimateText'
import Seo from '../components/Seo'

import Tink from '../images/logos/Tink'
import Almi from '../images/logos/Almi'
import Nordea from '../images/logos/Nordea'
import BankID from '../images/logos/BankID'
import Glesys from '../images/logos/Glesys'
import Angelholm from '../images/logos/Angelholm'

const VIDEO_URL = 'https://vimeo.com/507430421'
const VIMEO_CONFIG = {
	vimeo: {
		playerOptions: {
			muted: false,
			autoplay: false,
			loop: true,
			responsive: true,
		},
	},
}

const About = () => (
	<Fragment>
		<Seo
			title="Om oss"
			description="Vårt samhälle förbättras och förenklas ständigt genom
          digitaliseringen och nya digitala tjänster. Det gör att
          kostnaderna i den offentliga administrationen minskar,
          samtidigt som transparensen och rättssäkerheten ökar.
          Digitalisering och effektivisering är en självklar
          nödvändighet i de flesta verksamheter, men långt ifrån alla
          utnyttjar dess fulla potential."
		/>

		<div className="container mx-auto pb-24 px-6">
			<section className="flex flex-wrap md:w-10/12 lg:w-8/12 md:mx-auto pt-8 sm:pt-16 md:pt-20 lg:pt-16 mb-24">
				<VideoContainer className="relative w-full mb-8">
					<div className="overflow-hidden rounded-lg relative z-10 bg-white">
						<ReactPlayer
							width="100%"
							height="100%"
							url={VIDEO_URL}
							playing={false}
							controls={true}
							config={VIMEO_CONFIG}
						/>
					</div>
				</VideoContainer>
				<div className="max-w-2xl">
					<h2 className="text-xs sm:text-sm text-blue-500 uppercase font-semibold mb-2">
						Vår vision
					</h2>
					<h1 className="text-3xl md:text-4xl lg:text-5xl font-futura font-semibold text-white leading-tight mb-6">
						<AnimateText>Av gode män, för gode män.</AnimateText>
					</h1>
					<p className="mb-6 lg:mb-8 text-base md:text-lg text-white">
						Att vara ställföreträdare är ett viktigt och värdefullt
						samhällsuppdrag! Men det är ett uppdrag med många
						utmaningar, inte minst administrativa. Vi som engagerar
						oss som gode män och förvaltare förtjänar moderna och
						effektiva verktyg som underlättar våra uppdrag. Vår
						vision med Aider är att tillsammans med dig, och andra
						kollegor runt om i landet, åstadkomma just detta.
					</p>
					<p className="mb-6 lg:mb-8 text-base md:text-lg text-white">
						Målsättningen är att Aider ska utvecklas över tid, dra
						fördel av digitaliseringens möjligheter och anpassas
						utifrån våra gemensamma behov och på ett sätt som gynnar
						både oss och våra huvudmän.
					</p>
					<p className="mb-6 lg:mb-8 text-base md:text-lg text-white">
						Tillsammans gör vi det möjligt!
					</p>
					<div className="flex w-full items-center mb-8">
						<StaticImage
							className="h-12 w-12 rounded-full  mr-2 "
							alt="Ulf Mattsson"
							src="../images/team/ulf.png"
							placeholder="blurred"
							layout="fixed"
							width={48}
							height={48}
						/>

						<div>
							<h4 className="text-sm text-white font-medium leading-tight">
								Ulf Mattsson
							</h4>
							<span className="text-sm text-white opacity-70 leading-tight">
								God man och grundare, Aider
							</span>
						</div>
					</div>

					{/* Partner Section */}

					<div className="flex relative  justify-center flex-wrap border-t border-b border-blue-700 mt-16">
						<div className="w-6/12 md:w-4/12 lg:w-2/12 flex items-center justify-center py-5">
							<Tink className="h-8" />
						</div>
						<div className="w-6/12 md:w-4/12 lg:w-2/12 flex items-center justify-center py-5">
							<Almi className="h-7" />
						</div>
						<div className="w-6/12 md:w-4/12 lg:w-2/12 flex items-center justify-center py-5">
							<Nordea className="h-5" />
						</div>
						<div className="w-6/12 md:w-4/12 lg:w-2/12 flex items-center justify-center py-5">
							<BankID className="h-10 sm:h-10" />
						</div>
						<div className="w-6/12 md:w-4/12 lg:w-2/12 flex items-center justify-center py-5">
							<Glesys className="h-6" />
						</div>
						<div className="w-6/12 md:w-4/12 lg:w-2/12 pl-4 flex items-center justify-center py-5">
							<Angelholm className="h-16" />
						</div>
					</div>
				</div>
			</section>
		</div>
	</Fragment>
)

const VideoContainer = styled.div`
	aspect-ratio: 16 / 9;
	&:after {
		transform: scale(0.95) translate(0, -20px);
		${tw`block absolute top-0 left-0 rounded-lg w-full h-full z-0 bg-gradient-to-r from-blue-500 to-green-500 `};
	}
`

export default About
