import React from 'react'

const Almi = ({ ...props }) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 74 26"
		>
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M58.982 5.067c1.366 0 2.495-1.14 2.495-2.548 0-1.38-1.129-2.519-2.495-2.519-1.367 0-2.496 1.14-2.496 2.519 0 1.408 1.129 2.548 2.496 2.548zM56.939 21.615h4.088V6.579h-4.088v15.036zM19.424 21.615h4.088V.568h-4.088v21.047zM11.067 16.904a3.91 3.91 0 01-5.571 0 3.992 3.992 0 01-1.15-2.807c0-1.1.44-2.092 1.15-2.808a3.91 3.91 0 015.57 0 3.976 3.976 0 011.15 2.808c0 1.095-.44 2.087-1.15 2.807zM12.216 6.57v.823c-1.16-.715-2.477-1.073-3.938-1.073-2.127 0-3.942.759-5.446 2.277-1.51 1.517-2.262 3.35-2.262 5.5 0 2.147.752 3.98 2.262 5.502 1.504 1.518 3.32 2.277 5.446 2.277 1.461 0 2.777-.357 3.938-1.073v.796h3.776V6.568h-3.776zM54.017 14.097v7.518h-3.771v-7.518c0-1.1-.44-2.092-1.155-2.808a3.904 3.904 0 00-5.565 0 3.957 3.957 0 00-1.155 2.808v7.518h-3.77v-7.518c0-1.1-.44-2.092-1.155-2.808a3.894 3.894 0 00-2.783-1.165 3.895 3.895 0 00-2.782 1.165 3.956 3.956 0 00-1.155 2.808v7.518h-3.771V6.579h3.77v.813c1.166-.715 2.483-1.073 3.938-1.073 2.127 0 3.948.759 5.452 2.277.13.13.253.265.37.4.12-.135.243-.27.372-.4 1.504-1.518 3.325-2.277 5.452-2.277s3.948.759 5.452 2.277c1.504 1.517 2.256 3.35 2.256 5.5z"
				clipRule="evenodd"
			></path>
		</svg>
	)
}

export default Almi
