import React from 'react'

const Nordea = ({ ...props }) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 217 24"
		>
			<path
				d="M48.758.455h-5.474v22.6h5.474V.455zM169.607 23.055l10.911-22.6H174.9l-7.958 16.478L159.02.455h-5.618l10.911 22.6h5.294zM26.54 23.055L38.17.455h-5.905l-8.678 16.478L7.527.455H0l21.426 22.6h5.113zM190.421.455l-11.631 22.6h5.941l8.643-16.478 16.096 16.478h7.49L195.534.455h-5.113zM63.198 8.327v14.728H57.76V.455h5.438L76.99 15.149V.455h5.437v22.6H76.99L63.198 8.327zM96.327 8.327v14.728h-5.474V.455h5.474l13.756 14.694V.455h5.473v22.6h-5.473L96.327 8.327zM136.622 19.032c4.825 0 7.958-2.554 7.958-7.172 0-4.443-2.989-6.927-7.958-6.927-4.97 0-7.958 2.484-7.958 6.927 0 4.653 3.096 7.172 7.958 7.172zm-13.54-7.242c0-7.557 5.077-11.79 13.54-11.79 8.462 0 13.54 4.233 13.54 11.79 0 7.872-5.294 12.21-13.54 12.21-8.247 0-13.54-4.338-13.54-12.21z"
				fill="#fff"
			/>
		</svg>
	)
}

export default Nordea
