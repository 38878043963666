import React from 'react'

const Tink = ({ ...props }) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 69 32"
		>
			<path
				fill="#fff"
				d="M58.556 3.917c.692-.773 1.08-1.677 1.271-2.954l.055-.367h3.96v.464c0 .61-.208 1.782-.43 2.428-.85 2.463-2.87 4.59-5.23 5.503-.858.332-2.074.59-2.787.592l-.54.001V5.63l.64-.096c1.242-.184 2.26-.723 3.061-1.617zM60.213 10.5c1.27-2.476 3.67-4.28 6.337-4.763a8.6 8.6 0 011.16-.12l.54-.008v3.96l-.367.055c-1.435.214-2.277.608-3.137 1.468-.98.98-1.462 2.154-1.455 3.548.013 2.48 1.954 4.593 4.46 4.855l.499.052v4.058l-.67-.061c-1.861-.17-3.35-.738-4.817-1.839-1.53-1.149-2.63-2.772-3.203-4.732-.178-.607-.238-1.037-.275-1.965-.071-1.797.19-3.071.928-4.508z"
			></path>
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M3.346 12.347v2.764l-1.274.023-1.275.024-.024 1.664-.023 1.663h2.319l.04 3.998c.037 3.749.05 4.04.225 4.688.304 1.128.645 1.729 1.43 2.514.791.792 1.52 1.203 2.687 1.519 1.259.34 4.257.225 4.748-.182.063-.053.073-.58.03-1.695-.034-.89-.073-1.628-.087-1.64-.013-.012-.296.05-.629.136-1.42.373-3.037.119-3.684-.58-.566-.609-.548-.458-.575-4.802l-.025-3.953 2.401-.023 2.402-.023v-3.284l-2.398-.023-2.399-.023V9.584H3.346v2.763zm11.149-.991v1.771h4.148V9.584h-4.148v1.772zm23.68 9.076v10.847l2.01-.023 2.008-.023.024-2.038.023-2.039 1.043-1.16c.575-.637 1.07-1.187 1.103-1.221.033-.035 1.089 1.412 2.347 3.216l2.288 3.28 2.322.002c1.845.003 2.306-.02 2.24-.105-.045-.06-1.533-2.18-3.306-4.712-2.947-4.21-3.212-4.618-3.093-4.754.072-.082 1.4-1.563 2.95-3.292 1.55-1.728 2.82-3.177 2.82-3.219 0-.042-1.06-.076-2.361-.076h-2.361l-2.976 3.423-2.976 3.422-.022-6.188-.022-6.188h-4.061v10.848zm-8.815-5.388c-1.453.115-2.67.66-3.639 1.63-.668.667-.68.66-.681-.403a6.28 6.28 0 00-.056-.888l-.054-.271-1.912.023-1.912.023-.022 8.06-.022 8.058H25.125v-4.741c0-2.864.035-4.926.088-5.207.128-.675.523-1.448.957-1.874.983-.963 2.723-1.231 3.964-.61.794.396 1.21.99 1.434 2.049.097.455.122 1.595.124 5.479l.001 4.904h4.069l-.03-5.855c-.026-5.305-.043-5.904-.182-6.374-.573-1.934-1.873-3.296-3.631-3.804-.674-.195-1.655-.271-2.56-.2zm-14.825.178c-.023.06-.031 3.687-.02 8.06l.023 7.95H18.6V15.159l-2.011-.023c-1.563-.018-2.021.001-2.054.087z"
				clipRule="evenodd"
			></path>
		</svg>
	)
}

export default Tink
